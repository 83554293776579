<template>
  <Layout
    :is-short="isShortInput"
    :is-nested="isNested"
  >
    <template
      v-if="row.label"
      v-slot:label
    >
      {{ row.label }}
    </template>
    <template
      v-if="row.extra"
      v-slot:extra
    >
      {{ row.extra }}
    </template>
    <template
      v-if="row.prepend"
      v-slot:prepend
    >
      {{ row.prepend }}
    </template>

    <template v-slot:input>
      <div class="flex items-center">
        <input
          v-model="model"
          :disabled="isDisabled"
          class="flex-auto mr-1 p-1 w-20 border border-gray-800 bg-gray-700 focus:bg-gray-600 focus:border-gray-500 mr-4"
          :class="{ 'opacity-75 cursor-not-allowed' : isDisabled }"
          type="color"
        >
        <input
          v-model="model"
          type="text"
          class="flex-auto block border-gray-300 rounded-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 w-20 text-xs bg-gray-600 border-2 border-gray-600 rounded-sm py-2 px-2 text-white leading-tight focus:outline-none focus:bg-white focus:border-gray-900 focus:text-gray-800"
        >
      </div>
    </template>
  </Layout>
</template>
<script>
import inputs from './InputMixin'

const Layout = () => import('./Layout')

export default {
  name: 'ColorInput',

  components: {
    Layout
  },
  mixins: [inputs],

  props: ['row', 'rowkey', 'keypath'],

  data () {
    return {
      model: this.row.value
    }
  },

  computed: {
    isDisabled () {
      if (this.row.disabled) {
        return this.row.disabled
      }
      return false
    }
  }
}

</script>
